import React, {useEffect, useState} from "react";
import FacilityModal from "../modal/FacilityModal";
import CtaBarButton from "../base/cta-bar-button";
import {brandColor, getClassTextForColor, getEditorColor, renderColor} from "../../util/ColorUtil";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { getEditorColors, isItUndefined, modifyLinks } from "../../util/DataUtil";
import {getItemHeightPerRow} from "../../util/CSSUtil";
import { cleanHTML } from "../utils/htmlUtils";

const Icon = ({data, lang, global, width}) => {
    library.add(fas)
    library.add(fal)

    const [marketoTrigger, setMarketoTrigger] = useState(false);
    const emphasizedTextColor = getEditorColors(isItUndefined(data?.field_title_color), isItUndefined(data?.field_subtitle_color)).filter(x => x)[0];
    const [titleHeight, setTitleHeight] = useState([])

    useEffect(()=> {
        if (width >= 576){
            setTitleHeight(getItemHeightPerRow('item-title-icon', 2))
        }
    }, [data])

    const renderItems = (items) => {
        return items.map((item, index) => {
            return <div key={'icon-item-' + index} className={'item'}>
                <div>
                    {
                        item?.field_font_awesome_icons.length !== 0 && item?.field_font_awesome_icons[0].style &&
                        <FontAwesomeIcon
                            icon={[item?.field_font_awesome_icons[0]?.style, item?.field_font_awesome_icons[0]?.icon_name]}
                            style={{fill : (data?.field_icon_color && data?.field_icon_color[0]) ? brandColor(data?.field_icon_color[0]?.value) : ''}}
                        />
                    }
                </div>
                <span
                    style={{
                        height : titleHeight[index] > 0 ? titleHeight[index] + 'px' : ''
                    }}
                    dangerouslySetInnerHTML={{__html : cleanHTML(modifyLinks(item?.field_title_color_paragraph[0]?.value))}} className={'item-title-icon'}/>
                <span dangerouslySetInnerHTML={{__html : cleanHTML(modifyLinks(item?.field_subtitle_color_paragraph[0]?.value))}} className={'item-subtitle'}/>
            </div>
        })
    }

    return <div style={{backgroundColor : brandColor(data?.field_background_color[0]?.value)}}
                className={
                    'icon ' + getClassTextForColor(data?.field_background_color[0]?.value)
                }>
        <div className={'inner-container'}>
            <div className={'top'}>
                <div className={'left'} style={{marginBottom : (typeof data?.field_subtitle_color[0]?.value !== 'undefined' && data?.field_cta_bar_button_selection[0]?.cta_bar_button) ? '' : '0px'}}>
                    <span className={`title ${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{__html : cleanHTML(modifyLinks(data?.field_title_color[0]?.value))}}/>
                    <span className={`subtitle ${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{__html : cleanHTML(modifyLinks(data?.field_subtitle_color[0]?.value))}}/>
                </div>
                {
                    data?.field_cta_bar_button_selection[0]?.cta_bar_button &&
                    <CtaBarButton blockTypeClass={'icon'} bgColor={renderColor(getEditorColor(brandColor(data?.field_background_color[0]?.value)))} setMarketoTrigger={setMarketoTrigger} data={data?.field_cta_bar_button_selection[0]?.cta_bar_button} />
                }
                {marketoTrigger && <FacilityModal lang={lang} global={global} setTrigger={setMarketoTrigger} trigger={marketoTrigger}>{data.field_marketo_blocks[0]?.marketo_form_facility_block_type}</FacilityModal>}
            </div>
            <div className={'items-helper'} style={{marginTop : data?.field_cta_bar_button_selection[0]?.cta_bar_button ? ((width >= 576 && width < 992) ? '68px' : '') : ''}}>
                <div className={'items'}>
                    {renderItems(data?.field_image_title_subtitle_items?.font_awesome_title_subtitle_para)}
                </div>
            </div>
        </div>
    </div>
}

export default Icon;