export const getItemHeightPerRow = (
    className, perRow
) => {
    let titleHeights = document.getElementsByClassName(className);
    let titleCounts = titleHeights.length;
    let max = []
    let rowMax = 0

    for (let i = 0; i < titleCounts; i++){

        if ((i % perRow) === 0){
            rowMax = 0
        }

        if (titleHeights[i].offsetHeight > rowMax){
            rowMax = titleHeights[i].offsetHeight;
        }

        if ((i % perRow) === 0){
            continue
        }

        for (let j = 0; j < perRow; j++){
            max.push(rowMax)
        }
    }

    return max;
}
